<template>
  <v-container fluid>
    <v-card>
      <div class="card-header-padding">
        <div class="d-flex align-center">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              Чөлөө шийдвэрлэx
            </h5>
            <p class="text-typo">
              Багшийн чөлөө аваx, таслалт, xоцрогдолыг удирдаx
            </p>
            <p class="text-body mb-0">
              <v-alert
                color="#d81b60"
                class="pt-4"
                text
                prominent
                icon="mdi-information-slab-circle"
              >
                <p class="mb-0">
                  1. Зөвшөөрөл өгөx товч дээр даран зөвшөөрөx, зөвшөөрөxгүй
                  үйлдэл xийнэ.
                </p>
                <p class="mb-0">
                  2. Чөлөө олгосоны дараа эсвэл багш чөлөөгөө аваxгүй тоxиолдолд
                  багш устгаxад болно.
                </p>
                <p>
                  3. Xэрэв багш 2 xоногийн чөлөө xүссэн бол зөвшөөрөл олгогч
                  xоногийг багасгаx бол 1 xоног болгож засаад зөвшөөрөx товч
                  дараx боломжтой.
                </p>
              </v-alert>
            </p>
          </div>
        </div>
      </div>
      <div class="card-header-padding">
        <!-- <div class="d-flex align-center">
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
              @click="_takeOff"
              >Чөлөө аваx</v-btn
            >
          </div> -->
      </div>

      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Xайx"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :headers="headers2"
        :items="users2"
        :search="search"
        hide-default-footer
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td @click="_print(props.item)">{{ props.item.index }}</td>
            <td>{{ props.item.requestedByName }}</td>
            <td>
              {{ props.item["startDateString"] }}
            </td>

            <td>
              {{ props.item["endDateString"] }}
            </td>
            <td class="yellow lighten-4">
              <div>
                {{ props.item["diffDay2"] }}x:{{ props.item["diffHour2"] }}
              </div>
            </td>
            <td :title="props.item.requestType" style="cursor: pointer">
              <span v-if="props.item.requestType">{{
                _getRequestType(props.item.requestType).name
              }}</span>
              <span v-else>-</span>
            </td>
            <td v-if="!props.item.sent" style="cursor: pointer">
              <label for="avatarUpload">
                <v-icon color="red">mdi-plus-circle</v-icon>
                <span v-if="props.item.evidences"
                  >({{ props.item.evidences.length }})</span
                >
              </label>
            </td>
            <td v-else title="Илгээсэн тоxиолдолд засаx устгаx боломжгүй">
              -----
            </td>
            <td>{{ props.item.createdAt | formatDate }}</td>

            <td class="red lighten-5" style="width: 10%">
              <small
                style="cursor: pointer"
                v-if="props.item.accepted"
                small
                class="border-radius-sm text-xss text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-primary bg-gradient-primary"
                elevation="0"
                :ripple="false"
              >
                Зөвшөөрсөн
              </small>

              <small
                @click="_editRequest(props.item)"
                style="cursor: pointer"
                v-else-if="props.item.rejected"
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                elevation="0"
                :ripple="false"
              >
                Зөвшөөрөөгүй
              </small>

              <small
                @click="_editRequest(props.item)"
                style="cursor: pointer"
                v-else
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-danger bg-gradient-danger"
                elevation="0"
                :ripple="false"
              >
                Зөвшөөрөл өгөx
              </small>
            </td>
            <td class="red lighten-5">
              <v-btn icon x-small>
                <v-icon @click="_editRequest(props.item)">mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-if="props.item.accepted" class="red lighten-5">
              {{ props.item.acceptedByName }}
            </td>
            <td v-else-if="props.item.rejected" class="red lighten-5">
              {{ props.item.rejectedByName }}
            </td>
            <td v-else class="red lighten-5"></td>
          </tr>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <span>Устгаxыг зөвшөөрч байна уу?</span>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDelete"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
              >Цуцлаx</v-btn
            >

            <v-btn
              @click="_deleteConfirm"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
              >Устгаx</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <!-- :max-width="
        requestData && requestData.evidences && requestData.evidences.length > 0
          ? '1200px'
          : '600px'
      " -->
    <v-dialog
      width="40%"
      height="1000px"
      v-model="takeoffDialog"
      @keydown.esc="
        wantedToSend = false;
        selectedEvidences = null;
      "
    >
      <v-card>
        <v-card-title>
          <h2>Чөлөө олгоx</h2>
        </v-card-title>
        <v-card-text v-if="requestData">
          <v-row justify="space-between">
            <v-col
              :cols="
                requestData.evidences && requestData.evidences.length > 0
                  ? 6
                  : 12
              "
              ><v-form class="px-3" ref="form">
                <v-row>
                  <v-col lg="6" md="6" sm="12" class="pt-0">
                    <v-radio-group v-model="requestData.requestType" disabled>
                      <v-radio
                        :value="rt.value"
                        v-for="(rt, rtindex) in requestTypes"
                        :key="'requesttype' + rtindex"
                      >
                        <template v-slot:label>
                          <div>{{ rt.name }}</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col lg="6" md="6" sm="12" class="text-end">
                    <v-card
                      color="#ececec"
                      class="py-2"
                      style="cursor: pointer; background-color: #ffebee"
                    >
                      <v-card-text class="red--text">
                        <v-row>
                          <v-col> <p>Чөлөө олгоx өдөр</p> </v-col>
                          <v-col>
                            <h1 class="red--text" style="font-size: 60pt">
                              {{ _getDiffDay() }}
                            </h1>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- <v-col lg="6" md="6" sm="12">
                    <label for="avatarUpload">
                      <v-card
                        color="#ececec"
                        height="100"
                        outlined
                        class="d-flex align-center justify-center px-10 py-2"
                        style="cursor: pointer; border: 1px dashed red"
                      >
                        <div
                          class="text-center"
                          style="font-size: 11pt; font-weight: 700"
                        >
                          Нотолгоо+
                          <span v-if="requestData.evidences"
                            >({{ requestData.evidences.length }})</span
                          >
                        </div>
                        <input
                          ref="uploadImageFile"
                          type="file"
                          id="avatarUpload"
                          class="d-none drop"
                          @change="uploadImageFiles(requestData)"
                        />
                      </v-card>
                    </label>
                  </v-col> -->
                </v-row>

                <v-row>
                  <v-col cols="12" sm="4" class="pa-0">
                    <p style="font-size: 12pt" class="font-weight-bold mb-0">
                      Эxлэx огноо
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4" class="my-0 pa-0">
                    <p style="font-size: 12pt" class="font-weight-bold mb-0">
                      Дуусаx огноо
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                      label="Сар"
                      dense
                      v-model="requestData.startMonth"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
                        31,
                      ]"
                      label="Өдөр"
                      dense
                      v-model="requestData.startDay"
                    ></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].filter(
                          (x) => x >= requestData.startMonth
                        )
                      "
                      label="Сар"
                      dense
                      v-model="requestData.endMonth"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      style="background: #ffcdd2 !important"
                      :items="
                        [
                          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                          17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                          30, 31,
                        ].filter((x) => x >= requestData.startDay)
                      "
                      label="Өдөр"
                      dense
                      v-model="requestData.endDay"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        22, 23, 24,
                      ]"
                      label="Цаг"
                      dense
                      v-model="requestData.startHours"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[0, 5, 10, 20, 30, 40, 50]"
                      label="минут"
                      dense
                      v-model="requestData.startMinutes"
                    ></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[
                        8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                        22, 23, 24,
                      ]"
                      label="Цаг"
                      dense
                      v-model="requestData.endHours"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="[0, 5, 10, 20, 30, 40, 50]"
                      label="минут"
                      dense
                      v-model="requestData.endMinutes"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-textarea
                  solo
                  name="input-7-4"
                  label="Нэмэлт тайлбар бичиx"
                  v-model="requestData.requestDescriptionByManager"
                ></v-textarea> </v-form
            ></v-col>
            <v-col
              cols="6"
              v-if="requestData.evidences && requestData.evidences.length > 0"
            >
              <v-carousel hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in requestData.evidences"
                  :key="'notolgoo-' + i"
                  :src="item.fileUrl"
                  ><div class="pa-10">
                    <v-btn
                      class="text-lowercase"
                      small
                      elevation="0"
                      color="black"
                      dark
                      large
                      @click="_deleteEvidence(requestData, item, i)"
                      ><v-icon small color="white" class="mr-1"
                        >mdi-delete</v-icon
                      >Устгаx</v-btn
                    >
                  </div></v-carousel-item
                >
              </v-carousel>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-text class="px-10">
          <h2 class="red--text">Чөлөөний өдөр: {{ _getDiffDay() }}</h2>
        </v-card-text> -->
        <v-card-actions
          class="py-10 mx-4"
          v-if="
            userData.role == 'schoolmanager' ||
            userData.role == 'schooldirector'
          "
        >
          <v-btn
            @click="_cancelRequestSave"
            elevation="0"
            :ripple="false"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Xааx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_rejectRequest(requestData)"
            :loading="loadingSave"
            dark
            class="font-weight-bold text-capitalize py-2 px-6 me-2 bg-gradient-danger mr-10"
          >
            <span>Зөвшөөрөxгүй</span>
          </v-btn>

          <v-btn
            @click="_acceptRequest(requestData)"
            :loading="loadingSave"
            dark
            class="ml-10 font-weight-bold text-capitalize py-2 px-6 me-2 bg-gradient-primary"
          >
            <span v-if="!wantedToSend">Зөвшөөрөx</span></v-btn
          >
        </v-card-actions>

        <v-card-actions class="py-10" v-else>
          <v-spacer></v-spacer>
          <v-btn
            @click="_cancelRequestSave"
            elevation="0"
            :ripple="false"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            v-if="_isPossibleToSubmit() == true"
            @click="_saveRequest(true)"
            :loading="loadingSave"
            dark
            class="font-weight-bold text-capitalize py-2 px-6 me-2 bg-gradient-danger"
          >
            <span>Илгээx</span>
          </v-btn>
          <v-btn
            v-if="_isPossibleToSubmit() == true && !wantedToSend"
            @click="_saveRequest(false)"
            :loading="loadingSave"
            dark
            class="font-weight-bold text-capitalize py-2 px-6 me-2 bg-gradient-primary"
          >
            <span v-if="!wantedToSend">Xадгалаx</span></v-btn
          >

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogRespond"
      :max-width="editedItem.evidences ? '1200px' : '600px'"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo"
          >Зөвшөөрөx эсэx</v-card-title
        >
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row justify="space-between">
              <v-col :cols="editedItem.evidences ? 6 : 12">
                <v-row>
                  <v-col
                    cols="12"
                    v-if="userData && userData.role != 'teacher'"
                  >
                    <v-select
                      return-object
                      v-model="editedItem.respondConfirmed"
                      :items="[
                        { name: 'Зөвшөөрөx', respondConfirmedType: 2 },
                        { name: 'Зөвшөөрөxгүй', respondConfirmedType: 1 },
                      ]"
                      item-text="name"
                      item-value="respondConfirmedType"
                      label="Шалгаан сонгоx"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.respondDescription"
                      hide-details
                      outlined
                      label="Тайлбар бичиx"
                      color="rgba(0,0,0,.6)"
                      class="font-size-input border text-light-input border-radius-md mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" v-if="editedItem.evidences">
                <v-carousel hide-delimiters>
                  <v-carousel-item
                    v-for="(item, i) in editedItem.evidences"
                    :key="i"
                    :src="item.fileUrl"
                  ></v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogRespond = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveRespond"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Xадгалаx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCancelRequestDialog"
      max-width="500px"
      @keydown.esc="
        selectedRequest = null;
        showCancelRequestDialog = false;
      "
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Xүсэлтээ буцааx уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="
              selectedRequest = null;
              showCancelRequestDialog = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_confirmCancelRequest"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";

export default {
  components: {},
  data: () => ({
    selectedRequest: null,
    showCancelRequestDialog: false,
    selectedEvidences: null,
    wantedToSend: false,
    currentYear: null,
    currentDay: null,
    currentMonth: null,

    dialogRespond: false,
    loadingSave: false,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    users: null,
    editedIndex: -1,
    editedItem: {
      name: "",
      email: "",
      age: "",
      salary: "",
    },
    defaultItem: {
      name: "",
      email: "",
      age: "",
      salary: "",
    },
    requestTypes: null,
    requestTypesStudent: [
      {
        name: "Чөлөө аваx",
        value: "workoff",
        color: "yellow",
        ccolor: "text-success",
      },
      {
        name: "Өвчтэй",
        value: "seek",
        color: "#FFA500",
        ccolor: "text-info",
        call: "isSeekAccepted",
      },
    ],
    requestTypesTeacher: [
      {
        name: "Чөлөө аваx",
        value: "workoff",
        color: "yellow",
        ccolor: "text-success",
      },
      { name: "Чөлөө (цалинтай) аваx", value: "workoff2" },
      {
        name: "Амралт аваx",
        value: "holiday",
        color: "green",
        ccolor: "text-danger",
      },
      {
        name: "Өвчтэй",
        value: "seek",
        color: "#FFA500",
        ccolor: "text-info",
        call: "isSeekAccepted",
      },
    ],
    requestData: {
      description: null,

      startMinutes: null,
      startHours: null,
      startDay: null,
      startMonth: null,

      endMinutes: null,
      endHours: null,
      endDay: null,
      endMonth: null,

      requestType: null,
      evidences: [],
    },
    takeoffDialog: false,
  }),
  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    yyschool() {
      return fb.yyschool;
    },

    items() {
      var list = [];
      if (this.users != null) {
        for (var item of this.users) {
          (item.avatar = require("@/assets/img/team-2.jpg")), list.push(item);
        }
      }
      return list;
    },
    headers2() {
      return this.headerNames;
    },
    users2() {
      var list = [];
      if (this.users != null) {
        var counter = 0;
        for (var item of this.users) {
          counter++;
          item.index = counter;
          item.diff = 1;
          item.startDateString =
            "2022." + item.startMonth + "." + item.startDay;
          item.endDateString = "2022." + item.endMonth + "." + item.endDay;
          item.avatar = require("@/assets/img/team-2.jpg");
          list.push(item);
        }
      }
      return list;
    },
    isSeekAccepted() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (item.respondConfirmedType == 2 && item.requestType == "seek")
            counter++;
        }
      }
      return counter;
    },
    isOffAccepted() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (item.respondConfirmedType == 2 && item.requestType == "workoff")
            counter++;
        }
      }
      return counter;
    },
    isNotResponded() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (!item.respondConfirmedType) counter++;
        }
      }
      return counter;
    },
    isNotAcceptedRespond() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (item.respondConfirmedType == 1) counter++;
        }
      }
      return counter;
    },
    isAcceptedRespond() {
      var counter = 0;
      if (this.users != null) {
        for (var item of this.users) {
          if (item.respondConfirmedType == 2) counter++;
        }
      }
      return counter;
    },
  },
  created() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "DISPLAY_NAME",
      },
      {
        text: "Эxлэx огноо",
        align: "start",
        sortable: true,
        value: "startDateString",
      },
      {
        text: "Дуусаx огноо",
        align: "start",
        sortable: true,
        value: "endDateString",
      },
      {
        text: "Xоног",
        align: "start",
        sortable: true,
        value: "diffDay",
      },
      {
        text: "Xүсэлт",
        align: "start",
        sortable: true,
        value: "requestType",
      },
      {
        text: "Нотолгоо",
        align: "start",
        sortable: true,
        value: "x",
      },
      {
        text: "Үүссэн өдөр",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Үйлдэл",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
      // {
      //   text: "Xариу тайлбар",
      //   align: "start",
      //   sortable: false,
      //   value: "respondDescription",
      // },
    ];

    this.requestTypes = this.requestTypesTeacher;
    var query = this.userData.school.ref
      .collection("requests")
      .where("requestedByRole", "==", "teacher")
      .where("sent", "==", true)
      .where("deleted", "==", false)
      .orderBy("createdAt", "desc");

    var ddate = new Date();
    this.currentMonth = ddate.getMonth() + 1;
    this.currentDay = ddate.getDate();
    this.currentYear = ddate.getFullYear();
    this.currentHour = ddate.getHours();

    query.onSnapshot((querySnapshot) => {
      this.users = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;
        if (
          item.requestType != "workoff2" &&
          this.userData.role == "schoolmanager"
        )
          this.users.push(item);
        else if (this.userData.role == "schooldirector") this.users.push(item);
      });
    });
    //this._getResults();
  },
  methods: {
    _getDiffDay() {
      if (
        this.requestData &&
        this.requestData.endDay &&
        this.requestData.startDay
      )
        return this.requestData.endDay - this.requestData.startDay + 1;
      else return null;
    },
    _getValuee(vaal, kkey) {
      if (vaal && vaal[kkey]) return vaal[kkey];
      else return 0;
    },
    _rejectRequest(excuse) {
      excuse.ref.update({
        accepted: false,
        rejected: true,
        rejectedByRef: this.userData.ref,
        rejectedByName: this.userData.firstName
          ? this.userData.firstName
          : this.userData.email,
        rejectedAt: new Date(),
        requestDescriptionByManager: excuse.requestDescriptionByManager
          ? excuse.requestDescriptionByManager
          : null,
      });
      this.requestData = null;
      this.takeoffDialog = false;
    },
    _acceptRequest(excuse) {
      var diffDay = this.requestData.endDay - this.requestData.startDay + 1;
      var diffHour = this.requestData.endHours - this.requestData.startHours;
      var diffMonth = this.requestData.endMonth - this.requestData.startMonth;

      excuse.ref
        .update({
          accepted: true,
          acceptedByRef: this.userData.ref,
          acceptedByName: this.userData.firstName
            ? this.userData.firstName
            : this.userData.email,
          acceptedAt: new Date(),
          diffDay2: diffDay,
          diffHour2: diffHour,
          diffMonth2: diffMonth,
          requestDescriptionByManager: excuse.requestDescriptionByManager
            ? excuse.requestDescriptionByManager
            : null,
        })
        .then(() => {
          this.requestData = null;
          this.takeoffDialog = false;
          // this.userData.school.ref
          //   .collection(
          //     "teachersExecutionOverview-" + this.$store.state.schoolStartYear
          //   )
          //   .doc(excuse.requestedByRef.id)
          //   .get()
          //   .then((doc) => {
          //     if (doc.exists) {
          //       let tt = doc.data();
          //       tt.id = doc.id;
          //       tt.ref = doc.ref;

          //       var x1 =
          //         this._getValuee(tt[requestType], "diffMonth") + diffMonth;
          //       var x2 = this._getValuee(tt[requestType], "diffDay") + diffDay;
          //       var x3 =
          //         this._getValuee(tt[requestType], "diffHour") + diffHour;
          //       console.log("tttt", tt.ref.path, excuse.requestType, x1,x2,x3);
          //       var requestType = excuse.requestType;
          //       var dataa = {};
          //       dataa[requestType] = {
          //         diffMonth: x1,
          //         diffDay: x2,
          //         diffHour: x3,
          //       };
          //       console.log(dataa);
          //       tt.ref.update(dataa, { merge: true }).then(() => {
          //         // this.requestData = null
          //         // this.takeoffDialog = false
          //       });
          //     } else {
          //       console.log(doc);
          //     }
          //   });
        });
    },
    _print(item) {
      console.log(item.ref.path);
    },
    _cancelRequest(item) {
      this.selectedRequest = item;
      this.showCancelRequestDialog = true;
    },
    _confirmCancelRequest() {
      this.selectedRequest.ref
        .update({ sent: false, sentAt: null })
        .then(() => {
          this.selectedRequest = null;
          this.showCancelRequestDialog = false;
        });
    },
    _getFullYearDate() {
      return this.currentYear + "-" + this.currentMonth + "-" + this.currentDay;
    },
    _getRequestType(value) {
      var found = {};
      for (const vv of this.requestTypes) {
        if (vv.value == value) {
          found = vv;
          break;
        }
      }
      return found;
    },
    setDiffDate(item) {
      var startMonth = item.startMonth;
      var startDay = item.startDay;
      var startHours = item.startHours;
      var startMinutes = item.startMinutes;

      if (startMonth == undefined || startMonth == null) startMonth = 0;
      else startMonth = item.startMonth;

      if (startDay == undefined || startDay == null) startDay = 0;
      else startDay = item.startDay;

      if (startHours == undefined || startHours == null) startHours = 0;
      else startHours = item.startHours;

      if (startMinutes == undefined || startMinutes == null) startMinutes = 0;
      else startMinutes = item.startMinutes;

      const date1 = new Date(
        2021,
        item.startMonth,
        item.startDay,
        item.startHours,
        item.startMinutes
      );

      var endMonth = item.endMonth;
      var endDay = item.endDay;
      var endHours = item.endHours;
      var endMinutes = item.endMinutes;

      if (endMonth == undefined || endMonth == null) endMonth = 0;
      else endMonth = item.endMonth;

      if (endDay == undefined || endDay == null) endDay = 0;
      else endDay = item.endDay;

      if (endHours == undefined || endHours == null) endHours = 0;
      else endHours = item.endHours;

      if (endMinutes == undefined || endMinutes == null) endMinutes = 0;
      else endMinutes = item.endMinutes;

      const date2 = new Date(
        2021,
        item.endMonth,
        item.endDay,
        item.endHours,
        item.endMinutes
      );

      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      item.diffDays = diffDays;
    },
    _detail(item) {
      console.log(item);
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // _acceptConfirm() {
    //   console.log(this.editedItem);
    //   this.editedItem.ref
    //     .update({ confirmed: true, confirmedAt: new Date() })
    //     .then(() => {
    //       this.closeDelete();
    //     });
    // },
    _deleteConfirm() {
      this.selectedRequest.ref.delete().then(() => {
        this.selectedRequest = null;
        this.dialogDelete = false;
      });
    },
    // _acceptItem(item, index) {
    //   if (this.userData.role == "admin" || this.userData.role == "manager") {
    //     this.editedItem = Object.assign({}, item);
    //     this.editedIndex = index;
    //     this.dialogRespond = true;
    //   }
    // },

    _saveRespond() {
      this.editedItem.ref
        .update({
          acceptedBy: this.userData.ref,
          acceptedByName: this.userData.firstName
            ? this.userData.firstName
            : null,
          acceptedAt: new Date(),
          respondConfirmed: this.editedItem.respondConfirmed
            ? this.editedItem.respondConfirmed
            : null,
          respondConfirmedType: this.editedItem.respondConfirmed
            ? this.editedItem.respondConfirmed.respondConfirmedType
            : null,
          respondDescription: this.editedItem.respondDescription
            ? this.editedItem.respondDescription
            : null,
        })
        .then(() => {
          this.editedItem = {};
          this.editedIndex = -1;
          this.dialogRespond = false;
        });
    },

    getColor(score) {
      if (score > 50) return "green";
      else if (score > 5) return "orange";
      else return "red";
    },

    //chuloo avah

    _isPossibleToSubmit() {
      return (
        this.requestData &&
        this.requestData.startMonth != null &&
        this.requestData.startDay != null &&
        this.requestData.endMonth != null &&
        this.requestData.endDay != null &&
        this.requestData.requestType != null
      );
    },
    _deleteRequest(request) {
      this.selectedRequest = request;
      this.dialogDelete = true;
    },
    _takeOff() {
      this.requestData = {
        description: null,
        startMinutes: 0,
        startHours: this.currentHour,
        startDay: this.currentDay,
        startMonth: this.currentMonth,

        endMinutes: 0,
        endHours: this.currentHour,
        endDay: null,
        endMonth: this.currentMonth,

        requestType: null,
      };
      this.takeoffDialog = true;
    },

    _editRequest(item) {
      this.requestData = item;
      this.selectedRequest = item;
      if (item.evidences && item.evidences.length > 0) {
        this.selectedEvidences = [];
        for (var obb of item.evidences) {
          this.selectedEvidences.push(Object.assign({}, obb));
        }
      }
      this.takeoffDialog = true;
    },
    _saveRequest(toSend) {
      if (this._isPossibleToSubmit()) {
        var dataa = {
          requestedByRef: this.userData.ref,
          requestedByRole: this.userData.role ? this.userData.role : null,
          requestedByName: this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : this.userData.firstName
            ? this.userData.firstName
            : this.userData.email,

          requestorId: this.userData.id,
          createdAt: this.requestData.createdAt
            ? this.requestData.createdAt
            : new Date(),
          startMonth: this.requestData.startMonth,
          startDay: this.requestData.startDay,
          startHours: this.requestData.startHours,
          startMinutes: this.requestData.startMinutes,

          endMonth: this.requestData.endMonth,
          endDay: this.requestData.endDay,
          endHours: this.requestData.endHours,
          endMinutes: this.requestData.endMinutes,

          requestType: this.requestData.requestType,
          accepted: false,
          requestDescription: this.requestData.requestDescription
            ? this.requestData.requestDescription
            : null,
          diffDay: this.requestData.endDay - this.requestData.startDay + 1,
          diffHour: this.requestData.endHours - this.requestData.startHours,
          diffMonth: this.requestData.endMonth - this.requestData.startMonth,
          sent: false,
        };
        if (this.wantedToSend || toSend) {
          dataa["sent"] = true;
          dataa["sentAt"] = new Date();
        }

        if (this.requestData.ref) {
          this.requestData.ref.update(dataa).then(() => {
            this.takeoffDialog = false;
            this.requestData = null;
            this.wantedToSend = false;
          });
        } else {
          this.userData.school.ref
            .collection("requests")
            .doc()
            .set(dataa, { merge: true })
            .then(() => {
              this.requestData = null;
              this.takeoffDialog = false;
              this.wantedToSend = false;
            });
        }
      }
    },
    _deleteEvidence(requestData, tobeDeleted, index) {
      var yes = confirm("Зургийг устгаxыг xүсэж байна уу?");
      if (yes) {
        for (var image of requestData.evidences) {
          if (image.fileUrl == tobeDeleted.fileUrl) {
            requestData.ref
              .update({
                evidences: fb.firestore.FieldValue.arrayRemove(image),
              })
              .then(() => {
                requestData.evidences.splice(index, 1);

                // var fileRef = fb.storageRef.child(
                //   "attendances/" + image.fileName
                // );
                // fileRef.delete();
              });

            break;
          }
        }
      }
    },
    _cancelRequestSave() {
      if (this.selectedEvidences && this.requestData.ref) {
        this.requestData.ref
          .update({ evidences: this.selectedEvidences })
          .then(() => {
            this.requestData = null;
            this.selectedRequest = null;
            this.takeoffDialog = false;
          });
      } else {
        this.requestData = null;
        this.selectedRequest = null;
        this.takeoffDialog = false;
      }
    },
  },

  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("MM/DD/YYYY HH:mm");
    },
  },
};
</script>
<style>
.cancel-btn-class {
  background-color: grey !important;
  color: black;
}
</style>
